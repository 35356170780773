#root,
.App {
    width: 100%;
    height: 100%;
}
.component-lazy {
    position: fixed;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
#screens.mobile {
    width: 100%;
    height: 100%;
    // overflow-x: hidden;

    .authorization-layout {
        .auth-forms-container {
            padding: 15px 30px 26px;
            background-color: #fff;
            border-radius: 20px;

            .logo {
                img {
                    width: 150px;
                    display: block;
                    margin: 0 auto 15px;
                }
            }
        }
    }

    .dashboard-grid {
        position: relative;
        padding: 15px 10px;

        .NavBar-grid {
            // margin-bottom: 15px;
            padding: 5px;
            background: #ffffff;
            border-radius: 15px;

            .ShoppingCart-Icon {
                font-size: 23px;
                margin-right: 20px;
            }
            .MenuOutlined-Icon {
                font-size: 23px;
            }
        }
    }

    .form-wrapper {
        background: #ffffff;
        border-radius: $border-radius-base;
        padding: 10px;
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding: 6px;
    }
}

.menu-drawer {
    a:active {
        color: #ffffff;
    }
    .logout-button {
        padding: 15px;
    }
    .ant-collapse-content,
    .ant-collapse,
    .ant-collapse > .ant-collapse-item {
        border: 0;
        background-color: #ffffff;
    }
    .ant-collapse-content > .ant-collapse-content-box {
        padding-top: 0;
    }
    .single-menu {
        .ant-list-item {
            position: relative;
            padding: 12px 16px;
            padding-left: 40px;
            color: rgba(0, 0, 0, 0.85);
            line-height: 22px;
            cursor: pointer;
            border: 0;
        }
    }
}

.component-lazy {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-table-content {
    overflow-x: auto;
}